<template>
  <div>
    <div class="leads-table-area addcustomer-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="filterSearch.duration == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Day</span>
        </button>
      </div>
      <table class="display" id="customers_table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Be Back</th>
            <th>Active Leadsheets</th>
            <th>Previous Leadsheets</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>

    <!-- previous Leadsheets Modal -->
    <div
      class="modal fade"
      id="previousLeads"
      tabindex="-1"
      role="dialog"
      aria-labelledby="previousLeadsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="previousLeadsLabel">Leadsheets</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="customer-modal">
              <table
                class="table table-borderless table-hover previous-leads-tbl"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date Modified</th>
                    <th>Agent</th>
                    <th>Vehicle</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="plead in prev_leads" :key="plead.l_id">
                    <td>
                      <a class="idtable" :href="'lead-detail/' + plead.l_id">{{
                        plead.l_id
                      }}</a>
                    </td>
                    <td>{{ plead.modified_date }}</td>
                    <td>{{ plead.agent }}</td>
                    <td>{{ plead.v_make }}</td>
                    <td>
                      <span v-if="plead.l_status == 1">Active</span>
                      <span v-if="plead.l_status == 2">Contacted</span>
                      <span v-if="plead.l_status == 3">Scheduled</span>
                      <span v-if="plead.l_status == 4">Visit</span>
                      <span v-if="plead.l_status == 5">Demo</span>
                      <span v-if="plead.l_status == 6">Sold</span>
                      <span v-if="plead.l_status == 7">Lost</span>
                    </td>
                  </tr>
                  <tr v-if="prev_leads.length == 0">
                    <td colspan="5" class="not-found">No lead found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  name: "Customers",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      filterSearch: {
        duration: "",
        customDate: {},
        from: "",
        to: "",
        keyword: this.$route.query.keyword,
      },
      prev_leads: [],
    };
  },
  created() {
    let vm = this;
    this.$root.$on("action", function (action) {
      if (action == "updateCustomers") {
        vm.filterSearch.keyword = vm.$route.query.keyword;
        $("#customers_table").dataTable().fnDraw();
      }
    });
  },
  methods: {
    updateData(val) {
      if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.filterSearch.from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.filterSearch.to = moment(new Date(endDate)).format("YYYY-MM-DD");
      }
      this.filterSearch.duration = val;
      $("#customers_table").dataTable().fnDraw();
    },
    previousLeads(id) {
      axios
        .get("previous_leads/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.prev_leads = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewLead(id) {
      axios
        .get("add_new_lead_from_customer/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$router.push("/lead-detail/" + res.data.lead_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    let v = this;
    $("#customers_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: axios.defaults.baseURL + "customers_list",
          type: "POST",
          data: function (d) {
            d.keyword = v.filterSearch.keyword;
            d.duration = v.filterSearch.duration;
            d.from = v.filterSearch.from;
            d.to = v.filterSearch.to;
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
      });
    $("tbody").on("click", ".prev-leads", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.previousLeads(id);
      }
    });
    $("tbody").on("click", ".add-new-lead", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.addNewLead(id);
      }
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.addcustomer-table .timeline,
.task-table .timeline {
  text-align: right;
  width: 100%;
  right: 5px;
}
</style>